/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

declare var angular: angular.IAngularStatic;

//// CONTROLLER ////
export class BrandDialogController {
    //Bindable
    public originalName: string;
    public isFranchise: boolean;
    public searchBrands: string;

    static get $inject() {
        return [
            '$state',
            '$mdDialog',
            '$q',
            'brand',
            'friends',
            'advertisers',
            'defaultAdvertiser',
            'BrandsResourceFactory',
            'loginService',
        ];
    }

    constructor(
        public $state: any,
        public $mdDialog: any,
        public $q: any,
        public brand: any,
        public friends: any,
        public advertisers: any,
        public defaultAdvertiser: any,
        public BrandsResourceFactory: any,
        public loginService: any
    ) {
        /* BINDABLE: DATA */
        // this.brand = null;
        this.originalName = '';
        this.searchBrands = '';
        this.isFranchise = loginService.getSessionData().franchiseCustomer;
    }

    $onInit() {
        this.brand = angular.copy(this.brand);
        this.originalName = this.brand.name;
        this.defaultAdvertiser = this.defaultAdvertiser[0];

        if (this.defaultAdvertiser && !this.brand.advertiserId) {
            this.brand.advertiserId = this.defaultAdvertiser.id;
        }
    }

    cancel() {
        this.$mdDialog.cancel();
    }

    disableSave() {
        return !((this.brand.name || this.searchBrands) && this.brand.advertiserId);
    }

    save() {
        let vm = this;

        // check if the brand name is null (it is new) or is the same as the search text (they haven't selected a brand object in the type-ahead)
        if (
            !vm.brand.name ||
            vm.brand.name === vm.searchBrands ||
            (vm.originalName && vm.originalName === vm.searchBrands)
        ) {
            vm.brand.name = vm.searchBrands;
            this.$mdDialog.hide(vm.brand);
        } else {
            // they have chosen an existing brand name from the drop down, so we need to tell them and prevent a save.
            this.$mdDialog
                .show(
                    this.$mdDialog.alert({
                        title: 'Warning',
                        textContent: 'You cannot select an already existing Brand name within selected Advertiser. Please enter a unique name.',
                        ok: 'OK',
                        multiple: true,
                    })
                )
                .then(function () {
                    vm.brand.name = null;
                });
        }
    }

    findBrands(searchTerm:string) {
        let vm = this;

        return new Promise((resolve, reject) => {
            vm.BrandsResourceFactory.getAll(
                {
                    advertiserId: vm.brand.advertiserId,
                    providerAccountId: vm.brand.providerAccountId,
                    name: searchTerm,
                }
            ).subscribe(
                (brands:any) => {
                    resolve(brands);
                },
                (err:any) => {
                    resolve([]);
                    console.debug(err);
                }
            );
        });
    }

    changeAdvertiser() {
        let vm = this;

        vm.brand.name = '';
        vm.searchBrands = '';
    }
}
