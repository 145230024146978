/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.settings.agencies').controller('AdvertiserDialogController', ['$state', '$mdDialog', '$q', 'advertiser', 'friends', 'loginService', 'AdvertiserResourceFactory', function ($state, $mdDialog, $q, advertiser, friends, loginService, AdvertiserResourceFactory) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.advertiser = null;
    vm.friends = null;
    vm.originalName = null;
    vm.adsEnabled = loginService.getSessionData().account.adsEnabled;
    /* BINDABLE : METHODS*/

    vm.cancel = cancel;
    vm.save = save;
    vm.disableSave = disableSave;
    vm.findAdvertisers = findAdvertisers;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function cancel() {
      $mdDialog.cancel();
    }

    function disableSave() {
      // If ADS is enabled, always disable
      if (vm.adsEnabled) {
        return true;
      }

      if (vm.advertiser.name || vm.searchAdvertisers) {
        if (vm.advertiser.contactPhone) {
          return !vm.advertiser.contactPhone.match(/^(\(*[0-9]{3}\)*\s*|[0-9]{3}\-*)[0-9]{3}-*[0-9]{4}$/);
        }

        return false;
      }

      return true;
    }

    function save() {
      // check if the advertiser name is null (it is new) or is the same as the search text (they haven't selected an advertiser object in the type-ahead)
      if (!vm.advertiser.name || vm.advertiser.name === vm.searchAdvertisers || vm.originalName && vm.originalName === vm.searchAdvertisers) {
        vm.advertiser.name = vm.searchAdvertisers;
        $mdDialog.hide(vm.advertiser);
      } else {
        // they have chosen an existing advertiser name from the drop down, so we need to tell them and prevent a save.
        $mdDialog.show($mdDialog.alert({
          title: 'Warning',
          textContent: 'You cannot select an already existing Advertiser name.  Please enter a unique name.',
          ok: 'OK',
          multiple: true
        })).then(function () {
          vm.advertiser.name = null;
        });
      }
    }

    function findAdvertisers(searchTerm) {
      var deferred = $q.defer();
      AdvertiserResourceFactory.getAll({
        agencyId: vm.advertiser.agencyId,
        name: searchTerm
      }).subscribe(function (advertisers) {
        deferred.resolve(advertisers);
      }, function (err) {
        deferred.resolve([]);
        console.debug(err);
      });
      return deferred.promise;
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {
      vm.advertiser = angular.copy(advertiser);
      vm.originalName = vm.advertiser.name;
      vm.friends = friends;
    }
  }]);
})();