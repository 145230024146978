/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
import { BrandDialogController } from './brandDialog-controller';
import { CampaignDialogController } from './campaignDialog-controller';

(function () {
  'use strict';

  angular.module('comcast.settings.agencies').controller('AgencyDetailController', ['$scope', '$rootScope', '$state', '$stateParams', '$mdDialog', '$q', 'AgencyResourceFactory', 'uiGridConstants', 'BrandsResourceFactory', '$timeout', 'CampaignsResourceFactory', 'addelivery.event.constants', 'NotificationService', 'AdvertiserResourceFactory', 'ProviderResourceFactory', 'loginService', 'ReceiverResourceFactory', 'TapeVendorResourceFactory', 'QcVendorResourceFactory', 'ProdSvcsVendorResourceFactory', '$filter', 'AgencyService', function ($scope, $rootScope, $state, $stateParams, $mdDialog, $q, AgencyResourceFactory, uiGridConstants, BrandsResourceFactory, $timeout, CampaignsResourceFactory, EventConstants, NotificationService, AdvertiserResourceFactory, ProviderResource, loginService, ReceiverResource, TapeVendorResource, QcVendorResource, ProdSvcsResource, $filter, AgencyService) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this;
    /* BINDABLE : DATA */

    vm.agency = {};
    vm.allBrands = [];
    vm.allCampaigns = [];
    vm.advertisersGridApi = null;
    vm.brandsGridApi = null;
    vm.campaignsGridApi = null;
    vm.originalAgency = {};
    vm.friends = null;
    vm.disableDialogs = null;
    vm.advertisersGridOptions = {
      enableColumnMenus: false,
      appScopeProvider: vm,
      enableSorting: true,
      enableRowSelection: true,
      enableRowHeaderSelection: false,
      multiSelect: false,
      rowTemplate: '<div ng-click="grid.appScope.filterByAdvertiser()" ng-dblclick="grid.appScope.openAdvertisersDialog(row.entity)" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader}"  ui-grid-cell></div>',
      columnDefs: [{
        name: 'name',
        field: 'name',
        displayName: 'name',
        sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
        sort: {
          direction: uiGridConstants.ASC
        },
        headerCellTemplate: require('../customHeader-template.html')
      }, {
        name: 'contact',
        field: 'contactName',
        displayName: 'contact',
        sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
        headerCellTemplate: require('../customHeader-template.html')
      }, {
        name: 'active',
        field: 'active',
        displayName: 'active?',
        sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
        cellTemplate: '<div class="ui-grid-cell-contents"><md-checkbox ng-model="row.entity.active" class="md-primary" aria-label="Active Checkbox"></md-checkbox></div>',
        headerCellTemplate: require('../customHeader-template.html')
      }]
    };

    vm.advertisersGridOptions.onRegisterApi = function (gridApi) {
      vm.advertisersGridApi = gridApi;
    };

    vm.brandsGridOptions = {
      enableColumnMenus: false,
      appScopeProvider: vm,
      enableSorting: true,
      enableRowSelection: true,
      enableRowHeaderSelection: false,
      multiSelect: false,
      rowTemplate: '<div ng-click="grid.appScope.filterByBrand()" ng-dblclick="grid.appScope.openBrandsDialog(row.entity)" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader}"  ui-grid-cell></div>',
      columnDefs: [{
        name: 'name',
        field: 'name',
        displayName: 'name',
        sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
        sort: {
          direction: uiGridConstants.ASC
        },
        headerCellTemplate: require('../customHeader-template.html')
      }, {
        name: 'brandCode',
        field: 'code',
        displayName: 'brand code',
        sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
        headerCellTemplate: require('../customHeader-template.html')
      }, {
        name: 'defaultUploader',
        field: 'defaultFriendAccountId',
        displayName: 'default uploader',
        sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
        cellTemplate: '<div class="ui-grid-cell-contents">{{grid.appScope.fetchFriendName(row.entity.defaultFriendAccountId)}}</div>',
        headerCellTemplate: require('../customHeader-template.html')
      }]
    };

    vm.brandsGridOptions.onRegisterApi = function (gridApi) {
      vm.brandsGridApi = gridApi;
    };

    vm.campaignsGridOptions = {
      enableColumnMenus: false,
      appScopeProvider: vm,
      enableSorting: true,
      enableRowSelection: true,
      enableRowHeaderSelection: false,
      multiSelect: false,
      rowTemplate: '<div ng-dblclick="grid.appScope.openCampaignsDialog(row.entity)" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader}"  ui-grid-cell></div>',
      columnDefs: [{
        name: 'name',
        field: 'name',
        displayName: 'name',
        sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
        sort: {
          direction: uiGridConstants.ASC
        },
        headerCellTemplate: require('../customHeader-template.html')
      }]
    };

    vm.campaignsGridOptions.onRegisterApi = function (gridApi) {
      vm.campaignsGridApi = gridApi;
    };

    vm.deleteAgencyId = null;
    vm.deleteAdvertiserIds = [];
    vm.deleteBrandIds = [];
    vm.deleteCampaignIds = [];
    vm.isFranchise = loginService.getSessionData().franchiseCustomer;
    vm.adsEnabled = loginService.getSessionData().account.adsEnabled;
    vm.advertiserResource = AdvertiserResourceFactory;
    vm.brandResource = BrandsResourceFactory;
    vm.campaignResource = CampaignsResourceFactory;
    /* BINDABLE : METHODS*/

    vm.fetchFriendName = fetchFriendName;
    vm.filterByAdvertiser = filterByAdvertiser;
    vm.filterByBrand = filterByBrand;
    vm.openAdvertisersDialog = openAdvertisersDialog;
    vm.openBrandsDialog = openBrandsDialog;
    vm.openCampaignsDialog = openCampaignsDialog;
    vm.findAgencies = findAgencies;
    vm.deleteGridItem = deleteGridItem;
    vm.saveAgency = saveAgency;
    vm.resetAgency = resetAgency;
    vm.disableSave = disableSave;
    vm.deleteAgency = deleteAgency;
    /* EVENTS */
    //Register any event listeners

    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function fetchFriendName(friendId) {
      if (vm.friends) {
        for (var i = 0; i < vm.friends.length; i++) {
          if (friendId === vm.friends[i].id) {
            return vm.friends[i].name;
          }
        }
      }
    }

    function filterByAdvertiser() {
      var selectedRows = vm.advertisersGridApi.selection.getSelectedRows();

      if (selectedRows.length === 0) {
        vm.brandsGridOptions.data = vm.allBrands;
        vm.campaignsGridOptions.data = vm.allCampaigns;

        _refreshGrids();
      } else {
        var advertiserIDs = [];
        angular.forEach(selectedRows, function (advertiser) {
          advertiserIDs.push(advertiser.id);
        });
        BrandsResourceFactory.getAll({
          advertiserIDs: advertiserIDs
        }).subscribe(function (brands) {
          if (brands.length === 0) {
            vm.brandsGridOptions.data = []; // if there are no brands, then there are no campaigns either

            vm.campaignsGridOptions.data = [];
          } else {
            var brandIDs = [];
            vm.brandsGridOptions.data = brands;
            angular.forEach(brands, function (brand) {
              brandIDs.push(brand.id);
            });
            CampaignsResourceFactory.getAll({
              brandIDs: brandIDs.join(',')
            }).subscribe(function (campaigns) {
              vm.campaignsGridOptions.data = campaigns;
              vm.campaignsGridApi.core.refresh();

              _refreshGrids();
            });
          }
        });
      }
    }

    function filterByBrand() {
      var selectedRows = vm.brandsGridApi.selection.getSelectedRows();
      var brandIDs = [];

      if (selectedRows.length === 0) {
        vm.campaignsGridOptions.data = vm.allCampaigns;
        vm.campaignsGridApi.core.refresh();
      } else {
        angular.forEach(selectedRows, function (brand) {
          brandIDs.push(brand.id);
        });
        CampaignsResourceFactory.getAll({
          brandIDs: brandIDs.join(',')
        }).subscribe(function (campaigns) {
          vm.campaignsGridOptions.data = campaigns;
          vm.campaignsGridApi.core.refresh();
        });
      }
    }

    function openAdvertisersDialog(advertiser) {
      var dataForDialog = {};

      if (advertiser === 'new') {
        dataForDialog.id = 'new';
        dataForDialog.active = true;
        dataForDialog.agencyId = vm.agency.id;
      } else {
        dataForDialog = advertiser;
      }

      var dialog = $mdDialog.show({
        controller: 'AdvertiserDialogController',
        controllerAs: 'vm',
        template: require('./advertiserDialog-template.html'),
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        fullscreen: true,
        locals: {
          advertiser: dataForDialog,
          friends: vm.friends
        }
      });
      dialog.then(function (dialogAdvertiser) {
        if (dialogAdvertiser.id === 'new') {
          dialogAdvertiser.id = null;
          dialogAdvertiser.agencyId = vm.agency.id;
          dialogAdvertiser.providerAccountId = loginService.getSessionData().accountId;
          AdvertiserResourceFactory.create({}, dialogAdvertiser).subscribe(function (newAdvertiser) {
            NotificationService.showNotificationToast('Advertiser created successfully');
            vm.advertisersGridOptions.data.push(newAdvertiser);

            _resetAllGrids();
          });
        } else {
          AdvertiserResourceFactory.save({
            id: dialogAdvertiser.id
          }, dialogAdvertiser).subscribe(function () {
            NotificationService.showNotificationToast('Advertiser updated successfully');

            _resetAllGrids();
          });
        }
      }, function () {});
    }

    function openBrandsDialog(brand) {
      var dataForDialog = {};

      if (brand === 'new') {
        dataForDialog.id = 'new';
        dataForDialog.providerAccountId = loginService.getSessionData().accountId;
        dataForDialog.active = true; // Always best to default new things to active
      } else {
        dataForDialog = brand;
      }

      var dialog = $mdDialog.show({
        controller: BrandDialogController,
        controllerAs: 'vm',
        template: require('./brandDialog-template.html'),
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        fullscreen: true,
        locals: {
          brand: dataForDialog,
          friends: vm.friends,
          advertisers: vm.agency.advertisers,
          defaultAdvertiser: vm.advertisersGridApi.selection.getSelectedRows()
        }
      });
      dialog.then(function (dialogBrand) {
        if (dialogBrand.id === 'new') {
          dialogBrand.id = null;
          BrandsResourceFactory.create({}, dialogBrand).subscribe(function (newBrand) {
            NotificationService.showNotificationToast('Brand created successfully');
            vm.brandsGridOptions.data.push(newBrand);
            vm.allBrands.push(newBrand);

            _resetAllGrids();
          });
        } else {
          BrandsResourceFactory.save({
            id: dialogBrand.id
          }, dialogBrand).subscribe(function (savedBrand) {
            NotificationService.showNotificationToast('Brand updated successfully');

            _resetAllGrids();
          });
        }
      }, function () {});
    }

    function openCampaignsDialog(campaign) {
      var selectedBrand = vm.brandsGridApi.selection.getSelectedRows();
      var dataForDialog = {};

      if (campaign === 'new') {
        dataForDialog.id = 'new';
        dataForDialog.providerAccountId = loginService.getSessionData().accountId;
        dataForDialog.active = true;
      } else {
        dataForDialog = campaign;
      }

      var dialog = $mdDialog.show({
        controller: CampaignDialogController,
        controllerAs: 'vm',
        template: require('./campaignDialog-template.html'),
        parent: angular.element(document.body),
        clickOutsideToClose: false,
        fullscreen: true,
        locals: {
          campaign: dataForDialog,
          brands: vm.allBrands,
          defaultBrand: selectedBrand
        }
      });
      dialog.then(function (dialogCampaign) {
        if (dialogCampaign.id === 'new') {
          dialogCampaign.id = null;
          CampaignsResourceFactory.create({}, dialogCampaign).subscribe(function (newCampaign) {
            NotificationService.showNotificationToast('Campaign created successfully');
            vm.campaignsGridOptions.data.push(newCampaign.data);
            vm.allCampaigns.push(newCampaign.data);

            _resetAllGrids();
          });
        } else {
          CampaignsResourceFactory.save({
            id: dialogCampaign.id
          }, dialogCampaign).subscribe(function (savedCampaign) {
            NotificationService.showNotificationToast('Campaign updated successfully');

            _resetAllGrids();
          });
        }
      }, function () {});
    }

    function findAgencies(searchTerm) {
      var deferred = $q.defer();
      AgencyResourceFactory.getAll({
        name: searchTerm
      }).subscribe(function success(agencies) {
        deferred.resolve(agencies.rows);
      }, function failure(err) {
        deferred.resolve([]);
        console.debug(err);
      });
      return deferred.promise;
    }

    function deleteGridItem(resource, category, grid) {
      var selectedItem = grid.selection.getSelectedRows()[0];
      resource["delete"]({
        id: selectedItem.id
      }).subscribe(function () {
        NotificationService.showNotificationToast(category + ' successfully deleted.');

        _resetAllGrids();
      }, function (err) {
        console.log(err);
        NotificationService.showNotificationToast('This ' + category + ' is already associated to another item in the system and cannot be deleted.');
      });
    }

    function disableSave() {
      // If the form has not been modified (whether new or existing) prevent unnecessary saves
      if (!$scope.agencyDetailForm || $scope.agencyDetailForm !== undefined && !$scope.agencyDetailForm.$dirty) {
        return true;
      }

      if (_validatePhoneNumber()) {
        return $scope.agencyDetailForm.nameField.$invalid;
      }

      return true;
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them


    function _validatePhoneNumber() {
      if ($scope.agencyDetailForm.phoneNumber) {
        if ($scope.agencyDetailForm.phoneNumber.$viewValue) {
          return $scope.agencyDetailForm.phoneNumber.$viewValue.match(/^(\(*[0-9]{3}\)*\s*|[0-9]{3}\-*)[0-9]{3}-*[0-9]{4}$/);
        } else {
          return true;
        }
      }
    }

    function _refreshGrids() {
      vm.brandsGridApi.core.refresh();
      vm.campaignsGridApi.core.refresh();
    }

    function resetAgency() {
      if ($stateParams.id == 'new') {
        vm.agency = {};
        vm.searchAgencies = '';
      } else {
        vm.agency = angular.copy(vm.originalAgency);
        vm.searchAgencies = angular.copy(vm.originalAgency.name);
      }

      $scope.agencyDetailForm.$setPristine();
      $rootScope.agencyDetailForm.$setPristine();
    }

    function saveAgency() {
      // check if the agency is null (it is new) or is the same as the search text (they haven't selected an agency object in the type-ahead)
      if (!vm.agency.name || vm.agency.name === vm.searchAgencies || vm.originalAgency.name && vm.originalAgency.name === vm.searchAgencies) {
        vm.agency.name = vm.searchAgencies;

        if ($stateParams.id == 'new') {
          AgencyResourceFactory.create({}, vm.agency).subscribe(function (agency) {
            NotificationService.showNotificationToast('Successfully created agency!');
            $scope.agencyDetailForm.$setPristine();
            $rootScope.agencyDetailForm.$setPristine();
            $state.go('agencyDetails', {
              id: agency.id
            });
          });
        } else {
          AgencyResourceFactory.save({
            id: vm.agency.id
          }, vm.agency).subscribe(function () {
            NotificationService.showNotificationToast('Saved Agency successfully!');
            $scope.agencyDetailForm.$setPristine();
            $rootScope.agencyDetailForm.$setPristine();
            $state.go('agencyDetails', {
              id: vm.agency.id
            }, {
              reload: true
            });
          });
        }
      } else {
        // they have chosen an existing agency name from the drop down, so we need to tell them and prevent a save.
        $mdDialog.show($mdDialog.alert({
          title: 'Warning',
          textContent: 'You cannot select an already existing Agency name.  Please enter a unique name.',
          ok: 'OK'
        })).then(function () {
          vm.agency.name = null;
        });
      }
    }

    function _resetAllGrids() {
      vm.allBrands = [];
      vm.allCampaigns = [];
      vm.advertisersGridOptions.data = [];
      var advertiserIDs = [];
      var brandIDs = []; //delete agency reset Ids

      vm.deleteAgencyId = null;
      vm.deleteAdvertiserIds = [];
      vm.deleteBrandIds = [];
      vm.deleteCampaignIds = [];
      AgencyResourceFactory.get({
        id: $stateParams.id
      }).subscribe(function (agency) {
        vm.deleteAgencyId = agency.id;
        vm.agency = agency;
        vm.originalAgency = angular.copy(agency);
        vm.advertisersGridOptions.data = agency.advertisers;

        if (agency.advertisers.length === 0) {
          vm.allBrands = [];
          vm.brandsGridOptions.data = [];
          vm.allCampaigns = [];
          vm.campaignsGridOptions.data = [];
        } else {
          angular.forEach(vm.advertisersGridOptions.data, function (advertiser) {
            advertiserIDs.push(advertiser.id);
          });
          vm.deleteAdvertiserIds = advertiserIDs;
          BrandsResourceFactory.getAll({
            advertiserIDs: advertiserIDs
          }).subscribe(function (brands) {
            if (brands.length === 0) {
              vm.allBrands = brands;
              vm.brandsGridOptions.data = brands;
              vm.allCampaigns = [];
              vm.campaignsGridOptions.data = [];
            } else {
              vm.allBrands = brands;
              vm.brandsGridOptions.data = brands;
              angular.forEach(vm.brandsGridOptions.data, function (brand) {
                brandIDs.push(brand.id);
              });
              vm.deleteBrandIds = brandIDs;
              CampaignsResourceFactory.getAll({
                brandIDs: brandIDs.join(',')
              }).subscribe(function (campaigns) {
                vm.allCampaigns = campaigns;
                angular.forEach(campaigns, function (campaign) {
                  vm.deleteCampaignIds.push(campaign.id);
                });
                vm.campaignsGridOptions.data = campaigns;
              });
            }
          });
        }
      });
    }

    function deleteAgency() {
      var agencyId = vm.agency.id;
      var deleteAgencyReq = {
        agency: vm.deleteAgencyId,
        advertisers: vm.deleteAdvertiserIds,
        brands: vm.deleteBrandIds,
        campaigns: vm.deleteCampaignIds
      };
      AgencyResourceFactory.checkDeleteAgency(agencyId).subscribe(function (res) {
        if (res.orders.length > 0 || res.spots.length > 0) {
          AgencyService.openUnableDeleteAgencyModal();
        } else {
          AgencyService.openDeleteAgencyModal(deleteAgencyReq);
        }
      });
    }
    /**
     * Initialization for this controller.
     */


    function _init() {
      if ($stateParams.id == 'new') {
        vm.agency = {
          active: true
        };
        vm.disableDialogs = true;
      } else {
        _resetAllGrids();
      }

      var securityAccount = loginService.getSessionData();

      switch (securityAccount.accountType.replace(/_/g, '')) {
        case 'PROVIDER':
          ProviderResource.get({
            id: securityAccount.accountId
          }, securityAccount, function (account) {
            vm.friends = account.Friends;
          });
          break;

        case 'RECEIVER':
          ReceiverResource.get({
            id: securityAccount.accountId
          }, securityAccount, function (account) {
            vm.friends = account.Friends;
          });
          break;

        case 'FULFILLMENT':
          TapeVendorResource.get({
            id: securityAccount.accountId
          }, securityAccount, function (account) {
            vm.friends = account.Friends;
          });
          break;

        case 'QCVENDOR':
          QcVendorResource.get({
            id: securityAccount.accountId
          }, securityAccount, function (account) {
            vm.friends = account.Friends;
          });
          break;

        case 'PRODUCTIONSERVICESVENDOR':
          ProdSvcsResource.get({
            id: securityAccount.accountId
          }, securityAccount, function (account) {
            vm.friends = account.Friends;
          });
          break;

        default:
          NotificationService.showNotificationToast('Not a valid security account type');
      }

      $timeout(function () {
        $rootScope.agencyDetailForm = $scope.agencyDetailForm;
        vm.agencyDetailForm = $scope.agencyDetailForm;
      });
    }
  }]);
})();