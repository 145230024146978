/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

declare var angular: angular.IAngularStatic;

//// CONTROLLER ////
export class CampaignDialogController {
    //Bindable
    public originalName: string;
    public searchCampaigns: string;

    static get $inject() {
        return [
            '$state',
            '$mdDialog',
            '$q',
            'CampaignsResourceFactory',
            'campaign',
            'brands',
            'defaultBrand',
            'loginService',
        ];
    }

    constructor(
        public $state: any,
        public $mdDialog: any,
        public $q: any,
        public CampaignsResourceFactory: any,
        public campaign: any,
        public brands: any,
        public defaultBrand: any,
        public loginService: any,
    ) {
        /* BINDABLE: DATA */
        this.originalName = this.campaign.name;
        this.searchCampaigns = '';
    }

    $onInit() {
        this.campaign = angular.copy(this.campaign);
        this.defaultBrand = this.defaultBrand[0];

        if (this.defaultBrand && !this.campaign.brandId && this.campaign.id === 'new') {
            this.campaign.brandId = this.defaultBrand.id;
        }
    }

    cancel() {
        this.$mdDialog.cancel();
    }

    disableSave() {
        return !((this.campaign.name || this.searchCampaigns) && this.campaign.brandId);
    }

    save() {
        // check if the campaign name is null (it is new) or is the same as the search text (they haven't selected a campaign object in the type-ahead)
        if (
            !this.campaign.name ||
            this.campaign.name === this.searchCampaigns ||
            (this.originalName && this.originalName === this.searchCampaigns)
        ) {
            this.campaign.name = this.searchCampaigns;
            this.$mdDialog.hide(this.campaign);
        } else {
            // they have chosen an existing brand name from the drop down, so we need to tell them and prevent a save.
            this.$mdDialog
                .show(
                    this.$mdDialog.alert({
                        title: 'Warning',
                        textContent:
                            'You cannot select an already existing Campaign name within selected Brand.  Please enter a unique name.',
                        ok: 'OK',
                        multiple: true,
                    })
                )
                .then(() => {
                    this.campaign.name = null;
                });
        }
    }

    findCampaigns(searchTerm:string) {
        let vm = this;

        return new Promise((resolve, reject) => {
            vm.CampaignsResourceFactory.getAll(
                {
                    brandId: this.campaign.brandId,
                    name: searchTerm,
                }).subscribe(
                    (campaigns:any) => {
                        resolve(campaigns);
                        },
                    (err:any) => {
                        resolve([]);
                        console.debug(err);
                        });
            });
    }

    changeBrand() {
        this.campaign.name = '';
        this.searchCampaigns = '';
    }
}
