import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AgencyResource } from "../../../../common/communication/resources/agency-resource";
import { Subject } from "rxjs";
import { IDeleteAgencyReq } from "../../../../common/services/agency.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { takeUntil } from "rxjs/operators";
import { MigrationManager } from "../../../../common/communication/migrationManager-service";

@Component({
    selector: 'delete-agency-dialog',
    template: require('./delete-agency-dialog.component.html'),
})
export class DeleteAgencyDialogComponent implements OnInit, OnDestroy {
    private _destroy$ = new Subject<void>();
    deleteAgencyParams!: IDeleteAgencyReq;
    constructor( public dialogRef: MatDialogRef<DeleteAgencyDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public deleteAgencyReq: any,
        private AgencyResource : AgencyResource,
        private _snackBar: MatSnackBar,
        private migrationManager: MigrationManager
    ) {
        this.deleteAgencyParams = this.deleteAgencyReq.deleteAgencyReq;

    }
    ngOnInit() {
        
    }
    closeDeleteAgency() {
        this.dialogRef.close();
    }
    deleteAgency() {
        this.AgencyResource.deleteAgency(this.deleteAgencyParams).pipe(takeUntil(this._destroy$)).subscribe(
            (res: any) => {
                this.dialogRef.close();
                this._snackBar.open('Agency deleted successfully', '', {
                    duration: 2000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                });
                this.migrationManager.goToAgency();
            },
            (error: any) => {
                this.dialogRef.close();
                this._snackBar.open('An error occured deleting an agency, please try again later', '', {
                    duration: 3000,
                    horizontalPosition: 'end',
                    verticalPosition: 'top',
                });
            }
        );
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}