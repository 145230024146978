import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'unable-delete-agency-dialog',
    template: require('./unable-delete-agency-dialog.component.html'),
})
export class UnableDeleteAgencyDialogComponent {
    constructor( public dialogRef: MatDialogRef<UnableDeleteAgencyDialogComponent>) {}
    closeDeleteAgency() {
        this.dialogRef.close();
    }
}