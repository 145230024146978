/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
//// CONTROLLER ////
(function () {
  'use strict';

  angular.module('comcast.settings').controller('AgenciesController', ['$scope', '$state', '$stateParams', '$timeout', 'uiGridConstants', 'AgencyResourceFactory', function ($scope, $state, $stateParams, $timeout, uiGridConstants, AgencyResourceFactory) {
    /* PRIVATE : DATA */
    //Declare all private variables here

    /*
     * This is the `vm` object. It is a direct reference to the controller
     * which acts as our 'view-model' in angular. It also limits our need
     * to be accessing $scope directly. */

    /*jshint validthis:true*/
    var vm = this; // Store the search params in memory as we go

    var SearchParams = {};
    /* BINDABLE : DATA */

    vm.paginationOptions = {
      pageNumber: 1,
      pageSize: 25,
      sortField: undefined,
      sortDirection: undefined
    };
    vm.gridOptions = {
      paginationPageSizes: [25, 50, 75],
      paginationPageSize: 25,
      paginationCurrentPage: vm.paginationOptions.pageNumber,
      useExternalPagination: true,
      useExternalSorting: true,
      enableColumnMenus: false,
      enableRowSelection: true,
      enableRowHeaderSelection: false,
      modifierKeysToMultiSelect: true,
      multiSelect: true,
      appScopeProvider: vm,
      rowTemplate: '<div ng-dblclick="grid.appScope.openDetail(row.entity)" mbl-dblclick="grid.appScope.openDetail(row.entity)" ng-repeat="(colRenderIndex, col) in colContainer.renderedColumns track by col.colDef.name" class="ui-grid-cell" ng-class="{ \'ui-grid-row-header-cell\': col.isRowHeader}"  ui-grid-cell></div>',
      columnDefs: [{
        name: 'agencyName',
        field: 'name',
        displayName: 'name',
        sortDirectionCycle: [uiGridConstants.ASC, uiGridConstants.DESC],
        headerCellTemplate: require('../customHeader-template.html')
      }, {
        name: 'contactName',
        field: 'contactName',
        displayName: 'contact',
        enableSorting: false,
        headerCellTemplate: require('../customHeader-template.html')
      }, {
        name: 'active',
        field: 'active',
        displayName: 'active?',
        enableSorting: false,
        cellTemplate: "<div class=\"ui-grid-cell-contents\"><i ng-class=\"['far', {'fa-check-square': row.entity.active, 'fa-square': !row.entity.active}]\"></i></div>",
        headerCellTemplate: require('../customHeader-template.html')
      }],
      onRegisterApi: function onRegisterApi(gridApi) {
        $scope.gridApi = gridApi;
        $scope.gridApi.core.on.sortChanged($scope, function (grid, sortColumns) {
          if (sortColumns.length == 0) {
            vm.paginationOptions.sort = null;
          } else {
            vm.paginationOptions.sortField = sortColumns[0].field;
            vm.paginationOptions.sortDirection = sortColumns[0].sort.direction.toUpperCase();
          }

          vm.getPage(SearchParams);
        });
        gridApi.pagination.on.paginationChanged($scope, function (newPage, pageSize) {
          vm.paginationOptions.pageNumber = newPage;
          vm.paginationOptions.pageSize = pageSize;
          vm.getPage(SearchParams);
        });
      }
    };
    /* BINDABLE : METHODS */

    vm.newAgency = newAgency;

    vm.getPage = function (params) {
      params = params !== undefined ? params : {};
      params.limit = vm.paginationOptions.pageSize;
      params.offset = vm.paginationOptions.pageSize * (vm.paginationOptions.pageNumber - 1);

      if (vm.paginationOptions.sortField) {
        params.sortField = vm.paginationOptions.sortField;
      }

      if (vm.paginationOptions.sortDirection) {
        params.sortDirection = vm.paginationOptions.sortDirection;
      }

      if (params.name === null) {
        delete params.name;
      }

      AgencyResourceFactory.getAll(params).subscribe(function success(agencies) {
        vm.gridOptions.totalItems = agencies.count;
        vm.gridOptions.data = agencies.rows;
      }, function errorHandler(err) {
        // Something went wrong, so display nothing
        vm.gridOptions.totalItems = 0;
        vm.gridOptions.data = [];
      });
    };

    vm.openDetail = function (row) {
      angular.element(document.querySelector('.sidebar')).addClass('invisible');
      $state.go('agencyDetails', {
        id: row.id,
        pageNumber: vm.paginationOptions.pageNumber
      }).then(function () {
        // Apply the animation ONLY when this transition happens
        $timeout(function () {
          angular.element(document.querySelector('.sidebar')).removeClass('invisible');
          angular.element(document.querySelector('.detail-list-area .side-grid')).addClass('slide-left-transition');
        }, 0);
      });
    };
    /* EVENTS */
    //Register any event listeners


    $scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
      // Wait for the current digest cycle to finish, so the grid will display properly
      $timeout(function () {
        // Utilize parameters
        SearchParams = toParams;
        vm.getPage(SearchParams);
      });
    });
    /* INITIALIZATION */
    //Call controller initialization method here

    _init();
    /* IMPLEMENTATION : BINDABLE */


    function newAgency() {
      $state.go('agencyDetails', {
        id: 'new'
      });
    }
    /* IMPLEMENTATION : PRIVATE */
    // All private methods should start with '_' in order to distinguish them

    /**
     * Initialization for this controller.
     */


    function _init() {
      if ($stateParams.pageNumber && $stateParams.pageNumber !== 0) {
        vm.paginationOptions.pageNumber = parseInt($stateParams.pageNumber, 10);
        vm.gridOptions.paginationCurrentPage = vm.paginationOptions.pageNumber;
      }
    }
  }]);
})();