/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2017 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */
var dependencies = ['comcast.common.authentication', 'comcast.common.communication', 'comcast.common.navigation', 'comcast.settings', 'ngMaterial', 'ngMessages', 'comcast.directives'];
var defaultName = angular.module('comcast.settings.agencies', dependencies).config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state('agencies', {
    // Specify params that this state can access from the URL
    url: '/agencies?name&{includeInactive:bool}&pageNumber',
    //url: '/users?userEmail&userName&{includeArchives:bool}&userJob&callLetter&pageNumber',
    parent: 'settings',
    // Specify default values for state params
    params: {
      name: null,
      includeInactive: false,
      pageNumber: null
    },
    reloadOnSearch: true,
    views: {
      'settingsContent@settings': {
        template: '<ui-view name="agenciesContent"/>'
      },
      'settingsSearch@settings': {
        template: '<ui-view name="agenciesSearch"/>'
      },
      'agenciesContent@agencies': {
        template: require('./agencies-template.html'),
        controller: 'AgenciesController',
        controllerAs: 'vm'
      },
      'agenciesSearch@agencies': {
        template: require('./agenciesSearch-template.html'),
        controller: 'AgenciesSearchController',
        controllerAs: 'vm'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  }).state('agencyDetails', {
    url: '/:id',
    parent: 'agencies',
    params: {
      gridData: null,
      pageData: null
    },
    views: {
      agenciesContent: {
        template: require('./agencyDetail-template.html'),
        controller: 'AgencyDetailController',
        controllerAs: 'vm'
      },
      agenciesSearch: {
        template: require('./agencyDetailList-template.html'),
        controller: 'AgencyDetailListController',
        controllerAs: 'vm'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  });
}]).name;
export { defaultName as default, dependencies };